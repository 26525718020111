@import url('https://fonts.googleapis.com/css?family=Bitter|Raleway');



$primary: #0f4182; /* MAIN COLOR */
$secondary: #09475c; /* SECONDARY COLOR */
$third: #feca48; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$gray: #777; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */

.flash {
	display:none;
}
body {
	font-family: 'Raleway', sans-serif;
	font-size: 1.5em;
	h1,h2,h3,h4,h5,h6 {
		font-family: 'Bitter', serif;
	}
}
nav {
	z-index: 10;
}
.navbar {
	min-height: 75px;
}

.logo {
	max-height: 80px;
	@media(max-width: 767px){
		max-height:60px;
	}
}
.navbar-header{
	@media(max-width:767px){
		height: 75px;
	}
}
.navbar-brand {
    float: left;
    height: 75px;
    padding: 8px 8px;
    font-size: 18px;
    line-height: 20px;
}
.navbar .navbar-nav {
	height: 100px;
	@media(max-width:767px){
		height:130px;
	}

    > li > a {
		text-align: center;
		margin-top: 25px;
		display: flex;
		align-items: center;
		outline: none;
		color: $blk;


		@media (max-width: 767px) {
		    height: 22px;
		    display: inline-block;
				margin-top: 0;
				text-align: center;
		}
	    &:hover {
	    	background: transparent;
				// border-top: 1px solid $secondary;
	    	color: $primary;
				&:nth-child(1n+4){
					border: none;
				}
				@media(max-width: 767px){
					border: none;

				}
	    }
			 &:focus {
				color: $blk;
				background: transparent;
			 }
	}

		@media(max-width: 767px){
			max-height: auto;
		}

}
.lang {
	a {
		max-width: 20px;
		&:hover{
			border-top: 1px solid $wht;
		}
	}

}
.navbar-right {
	margin-top: 0px;
	z-index: 1;
}
.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}
.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	z-index: 1000;
	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}
.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}
.btn {
    position: relative;
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase;
    font-family: Lato, sans-serif;
    color: lighten($gray, 5%);
    font-size: 16px;
    line-height: 1.25em;
    text-decoration: none;
    text-align: center;
    box-shadow: none;
    padding: 15px 20px;
    margin: 0px;
    border-width: 4px;
    border-style: solid;
    border-color: lighten($gray, 5%);
    border-image: initial;
    transition: all 300ms;
		&:hover{
			color: $blk;
			border-color: $blk;
		}
}
.cta-box {
		margin-top: 20px;
    font-size: 1em;
    color: $wht;
    border: 2.5px solid lighten($wht, 25%);
    -webkit-transition: -webkit-transform 850ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: -webkit-transform 850ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 850ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 850ms cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 850ms cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-delay: 150ms;
    transition-delay: 150ms;
    box-sizing: border-box;
		height: 300px;
		padding: 25px;
		@media(max-width:991px){
			margin-top: 0px;
			height: 200px;
		}
		@media(max-width:500px){
			margin-top: 0px;
			height: 280px;
		}
		p {
			font-size: .7em;
			@media(max-width:500px){
				font-size: .6em;
			}
		}
}
.cta-box:nth-child(1n+1){
	background-color:lighten($secondary, 10%);
	@media(max-width:991px){
		margin-top: 10px;
	}
}
.cta-box:nth-child(1n+2){
	background-color:lighten($secondary, 0%);
	@media(max-width:991px){
		margin-top: 0px;
	}
}
.cta-box:nth-child(1n+3){
	background-color:darken($secondary,10%);
}
.cta-box:nth-child(1n+4){
	background-color:lighten($gray, 0%);
}
// sections
.bg-img{
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
}

.corp-a {
	background-image:url('../img/bg1.jpg');
	@extend .bg-img;
	padding: 250px 0;
	margin-top: 100px;
	h1 {
		font-family: 'Francois One', sans-serif;
		font-size: 6em;
		letter-spacing: 1px;
		color: $wht;
		font-weight: bold;
		text-shadow: 0 0 2px $wht;
		text-align: center;
		@media(max-width: 1024px){
			font-size: 4em;
		}
		@media(max-width:767px){
			font-size: 2.5em;
		}
		@media(max-width:560px){
			font-size: 1.8em;
		}
	}
	@media(max-width: 1024px){
			padding: 300px 0;

	}
	@media(max-width: 767px){
			padding: 150px 0;
			margin-top: 75px;
	}
	@media(max-width: 560px){
			padding:80px 0;
	}

}
section:before{
	content:"";
	display:block;
	height:220px; /* fixed header height*/
	margin:-200px 0 0; /* negative fixed header height */
}
.corp-b {
	background-color: lighten($gray, 45%);
	padding: 100px 0;
	font-size: 1.5em;
	font-weight: 300;
	h1 {
		font-size: 1.5em;
		@media(max-width:767px){
				font-size: 1em;
		}
	}

	img {
		padding: 15px;
		border: 5px solid lighten($gray, 25%);
		@media(max-width: 991px){
			margin-top: 35px;
		}
	}
	.cta-text {
		margin-bottom: 35px;
		font-size: .81em;
		@media(max-width:991px){
				font-size: .6em;
		}
	}
	a {
		@extend .btn;
	}
	@media(max-width:991px){
			padding: 50px 0 20px 0px ;
	}
}
.corp-c {
	padding: 100px 0;
	font-size: 1.5em;
	font-weight: 300;
	h1 {
		font-size: 1.5em;
		@media(max-width:767px){
				font-size: 1em;
		}
	}
	.cta-text {
		margin-bottom: 35px;
		font-size: 1em;
		@media(max-width:991px){
				font-size: .7em;
		}
	}
	a {
		@extend .btn;
	}
	@media(max-width:991px){
			padding: 50px 0 ;
	}
}
.contact {
	@extend .bg-img;
	background-image: url('../img/bg2.jpg');
	padding: 250px 0 ;
	@media(max-width:991px){
		padding: 100px 0 ;
	}
	@media(max-width:767px){
		padding: 70px 0 ;
	}
	@media(max-width:560px){
		padding:40px 0;
	}
	h1 {
		color: $wht;
		font-size: 6em;
		font-weight: bold;
		text-shadow: 0px 0px 1px $blk;
		@media(max-width:991px){
			font-size: 4em;
		}
		@media(max-width:767px){
			font-size: 2.5em;
		}
		span{
			font-weight: 300;
		}
	}
	p {
		color: $wht;
		font-size: 1.2em;
		text-align: center;
		@media(max-width:991px){
			font-size: 1em;
		}
	}
	.contact-info {

		i {
			margin-right: 10px;
			font-size: 1.5em;
		}
		p{
			font-size: 1em;
			@media(max-width:991px){
				font-size: 1em;
			}
		}
		a{
			font-size: 1em;
			color: $wht;
			outline:none;
			text-decoration: none;
			transition: all 0.5s ease 0s;
			@media(max-width:991px){
				font-size: 1em;
			}
			&:hover{
				color: $blk;
			}
		}
	}
}
// end sections

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $wht;
	a {
		color: $wht;
		&:hover {
			color: lighten($wht, 10%);
		}
	}
}
select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}
